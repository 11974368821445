package component

import androidx.compose.foundation.layout.Spacer
import androidx.compose.material.Surface
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.withFrameMillis
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.graphics.nativeCanvas
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.jetbrains.skia.Data
import org.jetbrains.skia.Rect
import org.jetbrains.skia.skottie.Animation
import org.jetbrains.skia.sksg.InvalidationController

@Composable
fun VectorAnimation(
    animationBytes: ByteArray,
    modifier: Modifier,
) {
    var seconds: Float by remember { mutableStateOf(0f) }
    var previousMillis: Long by remember { mutableStateOf(0L) }

    val invalidationController = remember { InvalidationController() }

    val animationState: MutableState<Animation?> = remember { mutableStateOf(null) }

    LaunchedEffect(null) {
        animationState.value = withContext(Dispatchers.Main) {
            Animation.makeFromData(Data.makeFromBytes(animationBytes))
        }
    }

    animationState.value.let { animation ->
        if (animation == null) {
            Spacer(modifier = modifier) // Placeholder while loading
        } else {

            Surface(
                modifier = modifier
                    .drawWithContent {
                        drawIntoCanvas { canvas ->
                            animation.seekFrameTime(seconds, invalidationController)
                            animation.render(
                                canvas = canvas.nativeCanvas,
                                dst = Rect.makeWH(size.width, size.height),
                            )
                        }
                    },
            ) {
                // Content will be drawn by modifier
            }

            LaunchedEffect(null) {
                while (seconds <= animation.duration) {
                    withFrameMillis { absoluteMillis ->
                        if (previousMillis > 0L) {
                            val deltaMillis = absoluteMillis - previousMillis
                            seconds += deltaMillis / 1000f
                        }
                        previousMillis = absoluteMillis
                    }
                }
            }
        }
    }
}
