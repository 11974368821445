@file:Suppress(
    "INVISIBLE_MEMBER",
    "INVISIBLE_REFERENCE",
    "EXPOSED_PARAMETER_TYPE"
) // WORKAROUND: ComposeLayer is internal

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.window.ComposeWindow
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Canvas
import org.w3c.dom.HTMLCanvasElement

@Composable
fun CanvasWithSkiaContent(content: @Composable () -> Unit) {
    val effect: DisposableEffectScope.(HTMLCanvasElement) -> DisposableEffectResult = {
        val canvasLayer = CanvasLayer(it)
        canvasLayer.setContent(content)
        onDispose { canvasLayer.dispose() }
    }

    val attr: AttrBuilderContext<HTMLCanvasElement> = {
        id("ComposeTarget")
        ref(effect)
    }
    Canvas(attrs = attr)
}

private class CanvasLayer(private var canvas: HTMLCanvasElement) {
    var size by mutableStateOf(IntSize(window.innerWidth, window.innerHeight))
    var scale by mutableStateOf(1f)

    val composeWindow = ComposeWindow()

    /*val composeLayer = ComposeLayer(
        layer = createSkiaLayer(),
        getTopLeftOffset = { Offset.Zero },
        platform = Platform.Empty,
        input = jsTextInputService.input
    )*/

    fun HTMLCanvasElement.fillViewportSize() {
        setAttribute("width", "${window.innerWidth}")
        setAttribute("height", "${window.innerHeight}")
    }

    fun reload(newSize: IntSize) {
        scale = window.devicePixelRatio.toFloat()
        with(composeWindow.layer) {
            // layer.state?.disposeCanvas()
            // https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
            with(canvas.cloneNode(false) as HTMLCanvasElement) {
                canvas.parentElement?.replaceChild(this, canvas)
                canvas.remove()
                canvas = this
                canvas.fillViewportSize()
            }
            layer.attachTo(canvas)
            layer.needRedraw()
            setDensity(Density(scale))
            setSize((newSize.width * scale).toInt(), (newSize.height * scale).toInt())
        }
    }

    // Not working :( ..
    fun HTMLCanvasElement.resetEvent() {
        for (event in listOf("mousedown", "mouseup", "mousemove", "wheel", "contextmenu", "keydown", "keyup")) {
            this.removeEventListener(event, {}, false)
        }
    }

    fun setContent(content: @Composable () -> Unit) = composeWindow.layer.setContent {
        window.addEventListener("resize", {
            size = IntSize(window.innerWidth, window.innerHeight)
        })

        LaunchedEffect(size) {
            reload(size)
        }

        Box(
            modifier = Modifier.fillMaxSize()//.scale(scale)
        ) {
            content()
        }
    }

    fun dispose() {
        // composeLayer.dispose()
        composeWindow.dispose()
    }
}
