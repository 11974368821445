import kotlinx.browser.document
import org.jetbrains.compose.web.renderComposableInBody
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.url.URL
import page.MainView

fun main() {
    onWasmReady {
        renderComposableInBody {
            CanvasWithSkiaContent {
                MainView(URL(document.URL).searchParams.get("page") ?: "")
            }
        }
    }
}