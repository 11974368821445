package page

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.Card
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.darkColors
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import kotlinx.browser.document

typealias Click = () -> Unit

val backgroundColor = Color(0xff0f172a)
val primaryColor = Color(0xff0064ff)

val onClickContact: Click = {
    document.location?.href = "https://yushosei.com/about"
}

@Composable
fun MainView(page: String) {
    MaterialTheme(
        colors = darkColors(
            surface = backgroundColor,
            primary = primaryColor,
            onPrimary = Color.White,
        )
    ) {
        Surface(modifier = Modifier.fillMaxSize()) {
            Card(modifier = Modifier.fillMaxSize(), backgroundColor = Color(0xff1e293b)) {
                when (page.lowercase()) {
                    PAGE_BUTTONS -> ButtonDemo()
                    PAGE_LOGIN -> LoginDemo()
                    //PAGE_FLIP -> Flip()
                    PAGE_TEST -> TEST()
                    PAGE_ANIMATION -> ArcRotationAnimation()
                    else -> Empty(onClickContact)
                }
            }
        }
    }
}