package page

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.size
import androidx.compose.material.Button
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import ktx.bounceClickEffect
import ktx.pressClickEffect
import ktx.shakeClickEffect

const val PAGE_BUTTONS = "buttons"

@Composable
fun ButtonDemo() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(
            16.dp,
            Alignment.CenterVertically
        ),
    ) {
        val text = remember { mutableStateOf("Click It!") }

        Text(
            text = text.value
        )

        Button(
            onClick = { text.value = "Press Clicked!" },
            modifier = Modifier.size(width = 128.dp, height = 64.dp).pressClickEffect(),
        ) {
            Text(
                text = "Press Button",
                textAlign = TextAlign.Center
            )
        }

        Button(
            onClick = { text.value = "Bounce Clicked!" },
            modifier = Modifier.size(width = 128.dp, height = 64.dp).bounceClickEffect(),
        ) {
            Text(
                text = "Bounce Button",
                textAlign = TextAlign.Center
            )
        }

        Button(
            onClick = { text.value = "Shake Clicked!" },
            modifier = Modifier.size(width = 128.dp, height = 64.dp).shakeClickEffect(),
        ) {
            Text(
                text = "Shake Button",
                textAlign = TextAlign.Center
            )
        }
    }
}   