package logic

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.*
import kotlin.time.Duration.Companion.seconds

class MainViewModel {

    private val logicScope = CoroutineScope(Job() + Dispatchers.Default)

    private val client = HttpClient(Js)

    suspend fun animationBytes(): ByteArray = client
        .get { url("./136279-love-emoji.json") }
        .readBytes()
        .also { println("Read ${it.size} animation bytes") }

    suspend fun fontBytes(): ByteArray = client
        .get { url("./ShantellSans-VariableFont_BNCE,INFM,SPAC,wght.ttf") }
        .readBytes()

    val secondsStateFlow: StateFlow<String> = run {
        var seconds = 0
        flow {
            while (true) {
                delay(1.seconds)
                seconds++
                emit(seconds)
            }
        }
            .map { it.toString() }
            .stateIn(
                scope = logicScope,
                started = SharingStarted.Lazily,
                initialValue = "0",
            )
    }
}
