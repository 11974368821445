package page

import androidx.compose.foundation.layout.*
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.platform.Typeface
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.VectorAnimation
import logic.MainViewModel
import org.jetbrains.skia.Data
import org.jetbrains.skia.Typeface

const val PAGE_TEST = "test"

@Composable
fun TEST(
    viewModel: MainViewModel = MainViewModel(),
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp),
    ) {
        Row(modifier = Modifier.fillMaxWidth()) {
            FontTestView(viewModel::fontBytes)
            Spacer(modifier = Modifier.weight(1.0f))
            Text(text = "Welcome", fontSize = 32.sp)
        }

        Row(
            modifier = Modifier
                .weight(1.0f)
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            AnimationTestView(provideAnimationBytes = viewModel::animationBytes)
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
        ) {
            Text(text = "Bottom", fontSize = 32.sp)
        }

        var text by remember { mutableStateOf("") }
        TextField(
            value = text,
            onValueChange = { text = it },
            label = { Text("Label") },
        )
    }
}

@Composable
fun AnimationTestView(provideAnimationBytes: suspend () -> ByteArray) {
    var animationBytes by remember { mutableStateOf<ByteArray?>(null) }
    LaunchedEffect(Unit) {
        animationBytes = provideAnimationBytes()
    }
    animationBytes?.let { bytes ->
        VectorAnimation(animationBytes = bytes, modifier = Modifier.size(300.dp))
    }
}

@Composable
fun FontTestView(provideFontBytes: suspend () -> ByteArray) {
    var fontBytes by remember { mutableStateOf<ByteArray?>(null) }
    LaunchedEffect(Unit) {
        fontBytes = provideFontBytes()
    }
    fontBytes?.let { bytes ->
        val skTypeface: Typeface = Typeface.makeFromData(Data.makeFromBytes(bytes))
        val typeface = Typeface(skTypeface)
        val fontFamily = FontFamily(typeface)
        Text("Hello, this is a test of font loading", fontFamily = fontFamily, fontSize = 24.sp)
    }
}
