package page

import androidx.compose.animation.animateContentSize
import androidx.compose.animation.core.LinearOutSlowInEasing
import androidx.compose.animation.core.tween
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import icons.Google
import icons.Icons
import icons.Twitter

const val PAGE_LOGIN = "login"

@Composable
fun LoginDemo() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(
            16.dp,
            Alignment.CenterVertically
        ),
    ) {
        var isLoading by remember { mutableStateOf(0) }

        val buttonStyle = MaterialTheme.typography.button.copy(
            fontWeight = FontWeight.Bold
        )

        Text(
            text = "Click it!",
            style = MaterialTheme.typography.h4.copy(
                fontWeight = FontWeight.Bold,
                color = Color.White
            )
        )

        Spacer(modifier = Modifier.height(16.dp))

        SignInButton(
            text = "Sign in with Google",
            isLoading = isLoading == 1,
            icon = rememberVectorPainter(Icons.Google),
            textStyle = buttonStyle.copy(
                color = Color.Black
            ),
            onClick = {
                isLoading = 1
            })

        SignInButton(
            text = "Sign in with Twitter",
            isLoading = isLoading == 2,
            icon = rememberVectorPainter(Icons.Twitter),
            textStyle = buttonStyle.copy(
                color = Color.White
            ),
            onClick = {
                isLoading = 2
            },
            progressIndicatorColor = Color.White,
            backgroundColor = Color(0xFF2696ea),
            borderColor = Color.Transparent
        )
    }
}

@Composable
fun SignInButton(
    text: String,
    loadingText: String = "Signing in...",
    icon: Painter,
    isLoading: Boolean = false,
    borderColor: Color = Color.LightGray,
    backgroundColor: Color = Color.White,
    progressIndicatorColor: Color = MaterialTheme.colors.primary,
    textStyle: TextStyle = MaterialTheme.typography.button,
    onClick: () -> Unit
) {
    Surface(
        modifier = Modifier.clickable(
            enabled = !isLoading,
            onClick = onClick
        ).clip(RoundedCornerShape(4.dp)),
        shape = RoundedCornerShape(4.dp),
        border = if (borderColor != Color.Transparent)
            BorderStroke(width = 1.dp, color = borderColor)
        else
            null,
        color = backgroundColor
    ) {
        Row(
            modifier = Modifier.wrapContentSize()
                .padding(16.dp)
                .animateContentSize(
                    animationSpec = tween(
                        durationMillis = 300,
                        easing = LinearOutSlowInEasing
                    )
                ),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
        ) {
            Icon(
                painter = icon,
                contentDescription = "SignInButton",
                tint = Color.Unspecified,
                modifier = Modifier.size(20.dp)
            )

            Spacer(modifier = Modifier.width(8.dp))

            Text(
                text = if (isLoading) loadingText else text,
                style = textStyle
            )
            if (isLoading) {
                Spacer(modifier = Modifier.width(16.dp))
                CircularProgressIndicator(
                    modifier = Modifier
                        .height(16.dp)
                        .width(16.dp),
                    strokeWidth = 3.dp,
                    color = progressIndicatorColor
                )
            }
        }
    }
}