package icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

public val Icons.Twitter: ImageVector
    get() {
        if (_twitter != null) {
            return _twitter!!
        }
        _twitter = Builder(name = "Twitter", defaultWidth = 248.0.dp, defaultHeight = 204.0.dp,
                viewportWidth = 248.0f, viewportHeight = 204.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(221.95f, 51.29f)
                curveToRelative(0.15f, 2.17f, 0.15f, 4.34f, 0.15f, 6.53f)
                curveToRelative(0.0f, 66.73f, -50.8f, 143.69f, -143.69f, 143.69f)
                verticalLineToRelative(-0.04f)
                curveToRelative(-27.44f, 0.04f, -54.31f, -7.82f, -77.41f, -22.64f)
                curveToRelative(3.99f, 0.48f, 8.0f, 0.72f, 12.02f, 0.73f)
                curveToRelative(22.74f, 0.02f, 44.83f, -7.61f, 62.72f, -21.66f)
                curveToRelative(-21.61f, -0.41f, -40.56f, -14.5f, -47.18f, -35.07f)
                curveToRelative(7.57f, 1.46f, 15.37f, 1.16f, 22.8f, -0.87f)
                curveToRelative(-23.56f, -4.76f, -40.51f, -25.46f, -40.51f, -49.5f)
                verticalLineToRelative(-0.64f)
                curveToRelative(7.02f, 3.91f, 14.88f, 6.08f, 22.92f, 6.32f)
                curveTo(11.58f, 63.31f, 4.74f, 33.79f, 18.14f, 10.71f)
                curveToRelative(25.64f, 31.55f, 63.47f, 50.73f, 104.08f, 52.76f)
                curveToRelative(-4.07f, -17.54f, 1.49f, -35.92f, 14.61f, -48.25f)
                curveToRelative(20.34f, -19.12f, 52.33f, -18.14f, 71.45f, 2.19f)
                curveToRelative(11.31f, -2.23f, 22.15f, -6.38f, 32.07f, -12.26f)
                curveToRelative(-3.77f, 11.69f, -11.66f, 21.62f, -22.2f, 27.93f)
                curveToRelative(10.01f, -1.18f, 19.79f, -3.86f, 29.0f, -7.95f)
                curveToRelative(-6.78f, 10.16f, -15.32f, 19.01f, -25.2f, 26.16f)
                close()
            }
        }
        .build()
        return _twitter!!
    }

private var _twitter: ImageVector? = null
